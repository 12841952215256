@import "./variables.css";
@import "./table.css";
@import "./paginator.css";
@import "./loading.css";
@import "./loaders/index.css";

.cursor-pointer {
    cursor: pointer;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}
