.paginator-scroll-manager {
    margin-top: 25px;
    display: block;
    width: 100%;
    overflow-x: auto;
}

.pagination-main-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.75em;
    line-height: 1.5em;
    padding: 0 0.1rem;
    min-width: 280px;
}

.pagination-limit-container {
    display: flex;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.pagination-limit-container select {
    padding: 4px;
    border-radius: 4px;
    border-color: var(--whiteC);
    color: var(--lightBlue);
    margin-right: 0.5rem;
}

.pagination-limit-container > span {
    color: var(--whiteA);
    background-color: var(--lightBlueHover);
    padding: 5px;
    border-radius: 4px;
    white-space: pre;
}

.pagination-controls-container {
    display: flex;
    user-select: none;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.pagination-controls-container > span {
    padding: 5px;
    border-radius: 4px;
    margin: 0 2px;
    transition-duration: 300ms;
    color: var(--whiteC);
}

.pagination-controls-container > span.selectable-offset {
    cursor: pointer;
    color: black;
}

.pagination-controls-container > span.selectable-offset:hover {
    color: var(--whiteA);
    background-color: var(--lightBlue);
}

.pagination-controls-container > span.selected-page {
    color: var(--whiteA);
    background-color: var(--lightBlueHover);
    cursor: initial;
}
