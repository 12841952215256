* {
  font-family: sans-serif; /* Change your font family */
}

.table-and-paginator-container {
  width: 100%;
}

/* Table container, enables horizontal scrolling when parent container is too small */
.table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* General table */
.content-table {
  position: relative;
  border-collapse: collapse;
  font-size: 0.9em;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Header row */
.content-table thead tr {
  /*background-color: var(--lightBlue);*/
  color: var(--white);
  text-align: left;
}

/* Header cells */
.content-table th {
  padding: 8px 15px;
  white-space: pre;
  background-color: var(--lightBlue);
  font-weight: normal;
  font-size: 1.05em;
  transition-duration: 700ms;
}

/* Header cell with sort true */
.sortable-th {
  cursor: pointer;
}

.sortable-th:hover {
  background-color: var(--lightBlueHover);
}

.sortable-th-with-icons > div {
  display: flex;
  align-items: center;
}

/* Div container for sort icons */
.sorting-icons-container {
  font-size: 0.7em;
  margin-left: 0.9em;
  user-select: none;
}

.sorting-icon {
  color: rgba(255, 255, 255, 0.3);
}

.sorting-icon-active {
  color: rgba(255, 255, 255, 0.85);
}

/* Body cells */
.content-table td {
  padding: 14px 15px;
  white-space: pre;
}

/* Body rows */
.content-table tbody tr {
  border-bottom: 1px solid var(--whiteB);
  border-left: 1px solid var(--whiteB);
  /*color: var(--darkBlue);*/
  color: rgba(0, 0, 0, 0.7);
}

/* Make every other row striped if enabled */
.frame-one-table-striped tbody tr:nth-of-type(even) {
  background-color: var(--whiteA);
}

/* Add bottom border to last row */
.content-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--lightBlue);
}

/* Styles if we have an "active" / selected row */
.content-table tbody tr.active-row {
  font-weight: bold;
  color: var(--lightBlue);
}

.frame-one-table-no-data-cell {
  text-align: center;
  font-style: italic;
}
